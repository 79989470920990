export const AREA_LIST = [
  { id: 100, county: '臺北市', city: '中正區' },
  { id: 103, county: '臺北市', city: '大同區' },
  { id: 104, county: '臺北市', city: '中山區' },
  { id: 105, county: '臺北市', city: '松山區' },
  { id: 106, county: '臺北市', city: '大安區' },
  { id: 108, county: '臺北市', city: '萬華區' },
  { id: 110, county: '臺北市', city: '信義區' },
  { id: 111, county: '臺北市', city: '士林區' },
  { id: 112, county: '臺北市', city: '北投區' },
  { id: 114, county: '臺北市', city: '內湖區' },
  { id: 115, county: '臺北市', city: '南港區' },
  { id: 116, county: '臺北市', city: '文山區' },
  { id: 200, county: '基隆市', city: '仁愛區' },
  { id: 201, county: '基隆市', city: '信義區' },
  { id: 202, county: '基隆市', city: '中正區' },
  { id: 203, county: '基隆市', city: '中山區' },
  { id: 204, county: '基隆市', city: '安樂區' },
  { id: 205, county: '基隆市', city: '暖暖區' },
  { id: 206, county: '基隆市', city: '七堵區' },
  { id: 207, county: '新北市', city: '萬里區' },
  { id: 208, county: '新北市', city: '金山區' },
  { id: 220, county: '新北市', city: '板橋區' },
  { id: 221, county: '新北市', city: '汐止區' },
  { id: 222, county: '新北市', city: '深坑區' },
  { id: 223, county: '新北市', city: '石碇區' },
  { id: 224, county: '新北市', city: '瑞芳區' },
  { id: 226, county: '新北市', city: '平溪區' },
  { id: 227, county: '新北市', city: '雙溪區' },
  { id: 228, county: '新北市', city: '貢寮區' },
  { id: 231, county: '新北市', city: '新店區' },
  { id: 232, county: '新北市', city: '坪林區' },
  { id: 233, county: '新北市', city: '烏來區' },
  { id: 234, county: '新北市', city: '永和區' },
  { id: 235, county: '新北市', city: '中和區' },
  { id: 236, county: '新北市', city: '土城區' },
  { id: 237, county: '新北市', city: '三峽區' },
  { id: 238, county: '新北市', city: '樹林區' },
  { id: 239, county: '新北市', city: '鶯歌區' },
  { id: 241, county: '新北市', city: '三重區' },
  { id: 242, county: '新北市', city: '新莊區' },
  { id: 243, county: '新北市', city: '泰山區' },
  { id: 244, county: '新北市', city: '林口區' },
  { id: 247, county: '新北市', city: '蘆洲區' },
  { id: 248, county: '新北市', city: '五股區' },
  { id: 249, county: '新北市', city: '八里區' },
  { id: 251, county: '新北市', city: '淡水區' },
  { id: 252, county: '新北市', city: '三芝區' },
  { id: 253, county: '新北市', city: '石門區' },
  { id: 260, county: '宜蘭縣', city: '宜蘭市' },
  { id: 261, county: '宜蘭縣', city: '頭城鎮' },
  { id: 262, county: '宜蘭縣', city: '礁溪鄉' },
  { id: 263, county: '宜蘭縣', city: '壯圍鄉' },
  { id: 264, county: '宜蘭縣', city: '員山鄉' },
  { id: 265, county: '宜蘭縣', city: '羅東鎮' },
  { id: 266, county: '宜蘭縣', city: '三星鄉' },
  { id: 267, county: '宜蘭縣', city: '大同鄉' },
  { id: 268, county: '宜蘭縣', city: '五結鄉' },
  { id: 269, county: '宜蘭縣', city: '冬山鄉' },
  { id: 270, county: '宜蘭縣', city: '蘇澳鎮' },
  { id: 272, county: '宜蘭縣', city: '南澳鄉' },
  { id: 290, county: '宜蘭縣', city: '釣魚臺列嶼' },
  { id: 300, county: '新竹市', city: '東區' },
  { id: 300, county: '新竹市', city: '北區' },
  { id: 300, county: '新竹市', city: '香山區' },
  { id: 302, county: '新竹縣', city: '竹北市' },
  { id: 303, county: '新竹縣', city: '湖口鄉' },
  { id: 304, county: '新竹縣', city: '新豐鄉' },
  { id: 305, county: '新竹縣', city: '新埔鎮' },
  { id: 306, county: '新竹縣', city: '關西鎮' },
  { id: 307, county: '新竹縣', city: '芎林鄉' },
  { id: 308, county: '新竹縣', city: '寶山鄉' },
  { id: 310, county: '新竹縣', city: '竹東鎮' },
  { id: 311, county: '新竹縣', city: '五峰鄉' },
  { id: 312, county: '新竹縣', city: '橫山鄉' },
  { id: 313, county: '新竹縣', city: '尖石鄉' },
  { id: 314, county: '新竹縣', city: '北埔鄉' },
  { id: 315, county: '新竹縣', city: '峨眉鄉' },
  { id: 320, county: '桃園市', city: '中壢區' },
  { id: 324, county: '桃園市', city: '平鎮區' },
  { id: 325, county: '桃園市', city: '龍潭區' },
  { id: 326, county: '桃園市', city: '楊梅區' },
  { id: 327, county: '桃園市', city: '新屋區' },
  { id: 328, county: '桃園市', city: '觀音區' },
  { id: 330, county: '桃園市', city: '桃園區' },
  { id: 333, county: '桃園市', city: '龜山區' },
  { id: 334, county: '桃園市', city: '八德區' },
  { id: 335, county: '桃園市', city: '大溪區' },
  { id: 336, county: '桃園市', city: '復興區' },
  { id: 337, county: '桃園市', city: '大園區' },
  { id: 338, county: '桃園市', city: '蘆竹區' },
  { id: 350, county: '苗栗縣', city: '竹南鎮' },
  { id: 351, county: '苗栗縣', city: '頭份市' },
  { id: 352, county: '苗栗縣', city: '三灣鄉' },
  { id: 353, county: '苗栗縣', city: '南庄鄉' },
  { id: 354, county: '苗栗縣', city: '獅潭鄉' },
  { id: 356, county: '苗栗縣', city: '後龍鎮' },
  { id: 357, county: '苗栗縣', city: '通霄鎮' },
  { id: 358, county: '苗栗縣', city: '苑裡鎮' },
  { id: 360, county: '苗栗縣', city: '苗栗市' },
  { id: 361, county: '苗栗縣', city: '造橋鄉' },
  { id: 362, county: '苗栗縣', city: '頭屋鄉' },
  { id: 363, county: '苗栗縣', city: '公館鄉' },
  { id: 364, county: '苗栗縣', city: '大湖鄉' },
  { id: 365, county: '苗栗縣', city: '泰安鄉' },
  { id: 366, county: '苗栗縣', city: '銅鑼鄉' },
  { id: 367, county: '苗栗縣', city: '三義鄉' },
  { id: 368, county: '苗栗縣', city: '西湖鄉' },
  { id: 369, county: '苗栗縣', city: '卓蘭鎮' },
  { id: 400, county: '臺中市', city: '中區' },
  { id: 401, county: '臺中市', city: '東區' },
  { id: 402, county: '臺中市', city: '南區' },
  { id: 403, county: '臺中市', city: '西區' },
  { id: 404, county: '臺中市', city: '北區' },
  { id: 406, county: '臺中市', city: '北屯區' },
  { id: 407, county: '臺中市', city: '西屯區' },
  { id: 408, county: '臺中市', city: '南屯區' },
  { id: 411, county: '臺中市', city: '太平區' },
  { id: 412, county: '臺中市', city: '大里區' },
  { id: 413, county: '臺中市', city: '霧峰區' },
  { id: 414, county: '臺中市', city: '烏日區' },
  { id: 420, county: '臺中市', city: '豐原區' },
  { id: 421, county: '臺中市', city: '后里區' },
  { id: 422, county: '臺中市', city: '石岡區' },
  { id: 423, county: '臺中市', city: '東勢區' },
  { id: 424, county: '臺中市', city: '和平區' },
  { id: 426, county: '臺中市', city: '新社區' },
  { id: 427, county: '臺中市', city: '潭子區' },
  { id: 428, county: '臺中市', city: '大雅區' },
  { id: 429, county: '臺中市', city: '神岡區' },
  { id: 432, county: '臺中市', city: '大肚區' },
  { id: 433, county: '臺中市', city: '沙鹿區' },
  { id: 434, county: '臺中市', city: '龍井區' },
  { id: 435, county: '臺中市', city: '梧棲區' },
  { id: 436, county: '臺中市', city: '清水區' },
  { id: 437, county: '臺中市', city: '大甲區' },
  { id: 438, county: '臺中市', city: '外埔區' },
  { id: 439, county: '臺中市', city: '大安區' },
  { id: 500, county: '彰化縣', city: '彰化市' },
  { id: 502, county: '彰化縣', city: '芬園鄉' },
  { id: 503, county: '彰化縣', city: '花壇鄉' },
  { id: 504, county: '彰化縣', city: '秀水鄉' },
  { id: 505, county: '彰化縣', city: '鹿港鎮' },
  { id: 506, county: '彰化縣', city: '福興鄉' },
  { id: 507, county: '彰化縣', city: '線西鄉' },
  { id: 508, county: '彰化縣', city: '和美鎮' },
  { id: 509, county: '彰化縣', city: '伸港鄉' },
  { id: 510, county: '彰化縣', city: '員林市' },
  { id: 511, county: '彰化縣', city: '社頭鄉' },
  { id: 512, county: '彰化縣', city: '永靖鄉' },
  { id: 513, county: '彰化縣', city: '埔心鄉' },
  { id: 514, county: '彰化縣', city: '溪湖鎮' },
  { id: 515, county: '彰化縣', city: '大村鄉' },
  { id: 516, county: '彰化縣', city: '埔鹽鄉' },
  { id: 520, county: '彰化縣', city: '田中鎮' },
  { id: 521, county: '彰化縣', city: '北斗鎮' },
  { id: 522, county: '彰化縣', city: '田尾鄉' },
  { id: 523, county: '彰化縣', city: '埤頭鄉' },
  { id: 524, county: '彰化縣', city: '溪州鄉' },
  { id: 525, county: '彰化縣', city: '竹塘鄉' },
  { id: 526, county: '彰化縣', city: '二林鎮' },
  { id: 527, county: '彰化縣', city: '大城鄉' },
  { id: 528, county: '彰化縣', city: '芳苑鄉' },
  { id: 530, county: '彰化縣', city: '二水鄉' },
  { id: 540, county: '南投縣', city: '南投市' },
  { id: 541, county: '南投縣', city: '中寮鄉' },
  { id: 542, county: '南投縣', city: '草屯鎮' },
  { id: 544, county: '南投縣', city: '國姓鄉' },
  { id: 545, county: '南投縣', city: '埔里鎮' },
  { id: 546, county: '南投縣', city: '仁愛鄉' },
  { id: 551, county: '南投縣', city: '名間鄉' },
  { id: 552, county: '南投縣', city: '集集鎮' },
  { id: 553, county: '南投縣', city: '水里鄉' },
  { id: 555, county: '南投縣', city: '魚池鄉' },
  { id: 556, county: '南投縣', city: '信義鄉' },
  { id: 557, county: '南投縣', city: '竹山鎮' },
  { id: 558, county: '南投縣', city: '鹿谷鄉' },
  { id: 600, county: '嘉義市', city: '東區' },
  { id: 600, county: '嘉義市', city: '西區' },
  { id: 602, county: '嘉義縣', city: '番路鄉' },
  { id: 603, county: '嘉義縣', city: '梅山鄉' },
  { id: 604, county: '嘉義縣', city: '竹崎鄉' },
  { id: 605, county: '嘉義縣', city: '阿里山鄉' },
  { id: 606, county: '嘉義縣', city: '中埔鄉' },
  { id: 607, county: '嘉義縣', city: '大埔鄉' },
  { id: 608, county: '嘉義縣', city: '水上鄉' },
  { id: 611, county: '嘉義縣', city: '鹿草鄉' },
  { id: 612, county: '嘉義縣', city: '太保市' },
  { id: 613, county: '嘉義縣', city: '朴子市' },
  { id: 614, county: '嘉義縣', city: '東石鄉' },
  { id: 615, county: '嘉義縣', city: '六腳鄉' },
  { id: 616, county: '嘉義縣', city: '新港鄉' },
  { id: 621, county: '嘉義縣', city: '民雄鄉' },
  { id: 622, county: '嘉義縣', city: '大林鎮' },
  { id: 623, county: '嘉義縣', city: '溪口鄉' },
  { id: 624, county: '嘉義縣', city: '義竹鄉' },
  { id: 625, county: '嘉義縣', city: '布袋鎮' },
  { id: 630, county: '雲林縣', city: '斗南鎮' },
  { id: 631, county: '雲林縣', city: '大埤鄉' },
  { id: 632, county: '雲林縣', city: '虎尾鎮' },
  { id: 633, county: '雲林縣', city: '土庫鎮' },
  { id: 634, county: '雲林縣', city: '褒忠鄉' },
  { id: 635, county: '雲林縣', city: '東勢鄉' },
  { id: 636, county: '雲林縣', city: '臺西鄉' },
  { id: 637, county: '雲林縣', city: '崙背鄉' },
  { id: 638, county: '雲林縣', city: '麥寮鄉' },
  { id: 640, county: '雲林縣', city: '斗六市' },
  { id: 643, county: '雲林縣', city: '林內鄉' },
  { id: 646, county: '雲林縣', city: '古坑鄉' },
  { id: 647, county: '雲林縣', city: '莿桐鄉' },
  { id: 648, county: '雲林縣', city: '西螺鎮' },
  { id: 649, county: '雲林縣', city: '二崙鄉' },
  { id: 651, county: '雲林縣', city: '北港鎮' },
  { id: 652, county: '雲林縣', city: '水林鄉' },
  { id: 653, county: '雲林縣', city: '口湖鄉' },
  { id: 654, county: '雲林縣', city: '四湖鄉' },
  { id: 655, county: '雲林縣', city: '元長鄉' },
  { id: 700, county: '臺南市', city: '中西區' },
  { id: 701, county: '臺南市', city: '東區' },
  { id: 702, county: '臺南市', city: '南區' },
  { id: 704, county: '臺南市', city: '北區' },
  { id: 708, county: '臺南市', city: '安平區' },
  { id: 709, county: '臺南市', city: '安南區' },
  { id: 710, county: '臺南市', city: '永康區' },
  { id: 711, county: '臺南市', city: '歸仁區' },
  { id: 712, county: '臺南市', city: '新化區' },
  { id: 713, county: '臺南市', city: '左鎮區' },
  { id: 714, county: '臺南市', city: '玉井區' },
  { id: 715, county: '臺南市', city: '楠西區' },
  { id: 716, county: '臺南市', city: '南化區' },
  { id: 717, county: '臺南市', city: '仁德區' },
  { id: 718, county: '臺南市', city: '關廟區' },
  { id: 719, county: '臺南市', city: '龍崎區' },
  { id: 720, county: '臺南市', city: '官田區' },
  { id: 721, county: '臺南市', city: '麻豆區' },
  { id: 722, county: '臺南市', city: '佳里區' },
  { id: 723, county: '臺南市', city: '西港區' },
  { id: 724, county: '臺南市', city: '七股區' },
  { id: 725, county: '臺南市', city: '將軍區' },
  { id: 726, county: '臺南市', city: '學甲區' },
  { id: 727, county: '臺南市', city: '北門區' },
  { id: 730, county: '臺南市', city: '新營區' },
  { id: 731, county: '臺南市', city: '後壁區' },
  { id: 732, county: '臺南市', city: '白河區' },
  { id: 733, county: '臺南市', city: '東山區' },
  { id: 734, county: '臺南市', city: '六甲區' },
  { id: 735, county: '臺南市', city: '下營區' },
  { id: 736, county: '臺南市', city: '柳營區' },
  { id: 737, county: '臺南市', city: '鹽水區' },
  { id: 741, county: '臺南市', city: '善化區' },
  { id: 742, county: '臺南市', city: '大內區' },
  { id: 743, county: '臺南市', city: '山上區' },
  { id: 744, county: '臺南市', city: '新市區' },
  { id: 745, county: '臺南市', city: '安定區' },
  { id: 800, county: '高雄市', city: '新興區' },
  { id: 801, county: '高雄市', city: '前金區' },
  { id: 802, county: '高雄市', city: '苓雅區' },
  { id: 803, county: '高雄市', city: '鹽埕區' },
  { id: 804, county: '高雄市', city: '鼓山區' },
  { id: 805, county: '高雄市', city: '旗津區' },
  { id: 806, county: '高雄市', city: '前鎮區' },
  { id: 807, county: '高雄市', city: '三民區' },
  { id: 811, county: '高雄市', city: '楠梓區' },
  { id: 812, county: '高雄市', city: '小港區' },
  { id: 813, county: '高雄市', city: '左營區' },
  { id: 814, county: '高雄市', city: '仁武區' },
  { id: 815, county: '高雄市', city: '大社區' },
  { id: 820, county: '高雄市', city: '岡山區' },
  { id: 821, county: '高雄市', city: '路竹區' },
  { id: 822, county: '高雄市', city: '阿蓮區' },
  { id: 823, county: '高雄市', city: '田寮區' },
  { id: 824, county: '高雄市', city: '燕巢區' },
  { id: 825, county: '高雄市', city: '橋頭區' },
  { id: 826, county: '高雄市', city: '梓官區' },
  { id: 827, county: '高雄市', city: '彌陀區' },
  { id: 828, county: '高雄市', city: '永安區' },
  { id: 829, county: '高雄市', city: '湖內區' },
  { id: 830, county: '高雄市', city: '鳳山區' },
  { id: 831, county: '高雄市', city: '大寮區' },
  { id: 832, county: '高雄市', city: '林園區' },
  { id: 833, county: '高雄市', city: '鳥松區' },
  { id: 840, county: '高雄市', city: '大樹區' },
  { id: 842, county: '高雄市', city: '旗山區' },
  { id: 843, county: '高雄市', city: '美濃區' },
  { id: 844, county: '高雄市', city: '六龜區' },
  { id: 845, county: '高雄市', city: '內門區' },
  { id: 846, county: '高雄市', city: '杉林區' },
  { id: 847, county: '高雄市', city: '甲仙區' },
  { id: 848, county: '高雄市', city: '桃源區' },
  { id: 849, county: '高雄市', city: '那瑪夏區' },
  { id: 851, county: '高雄市', city: '茂林區' },
  { id: 852, county: '高雄市', city: '茄萣區' },
  { id: 817, county: '高雄市', city: '東沙群島' },
  { id: 819, county: '高雄市', city: '南沙群島' },
  { id: 880, county: '澎湖縣', city: '馬公市' },
  { id: 881, county: '澎湖縣', city: '西嶼鄉' },
  { id: 882, county: '澎湖縣', city: '望安鄉' },
  { id: 883, county: '澎湖縣', city: '七美鄉' },
  { id: 884, county: '澎湖縣', city: '白沙鄉' },
  { id: 885, county: '澎湖縣', city: '湖西鄉' },
  { id: 900, county: '屏東縣', city: '屏東市' },
  { id: 901, county: '屏東縣', city: '三地門鄉' },
  { id: 902, county: '屏東縣', city: '霧臺鄉' },
  { id: 903, county: '屏東縣', city: '瑪家鄉' },
  { id: 904, county: '屏東縣', city: '九如鄉' },
  { id: 905, county: '屏東縣', city: '里港鄉' },
  { id: 906, county: '屏東縣', city: '高樹鄉' },
  { id: 907, county: '屏東縣', city: '鹽埔鄉' },
  { id: 908, county: '屏東縣', city: '長治鄉' },
  { id: 909, county: '屏東縣', city: '麟洛鄉' },
  { id: 911, county: '屏東縣', city: '竹田鄉' },
  { id: 912, county: '屏東縣', city: '內埔鄉' },
  { id: 913, county: '屏東縣', city: '萬丹鄉' },
  { id: 920, county: '屏東縣', city: '潮州鎮' },
  { id: 921, county: '屏東縣', city: '泰武鄉' },
  { id: 922, county: '屏東縣', city: '來義鄉' },
  { id: 923, county: '屏東縣', city: '萬巒鄉' },
  { id: 924, county: '屏東縣', city: '崁頂鄉' },
  { id: 925, county: '屏東縣', city: '新埤鄉' },
  { id: 926, county: '屏東縣', city: '南州鄉' },
  { id: 927, county: '屏東縣', city: '林邊鄉' },
  { id: 928, county: '屏東縣', city: '東港鎮' },
  { id: 929, county: '屏東縣', city: '琉球鄉' },
  { id: 931, county: '屏東縣', city: '佳冬鄉' },
  { id: 932, county: '屏東縣', city: '新園鄉' },
  { id: 940, county: '屏東縣', city: '枋寮鄉' },
  { id: 941, county: '屏東縣', city: '枋山鄉' },
  { id: 942, county: '屏東縣', city: '春日鄉' },
  { id: 943, county: '屏東縣', city: '獅子鄉' },
  { id: 944, county: '屏東縣', city: '車城鄉' },
  { id: 945, county: '屏東縣', city: '牡丹鄉' },
  { id: 946, county: '屏東縣', city: '恆春鎮' },
  { id: 947, county: '屏東縣', city: '滿州鄉' },
  { id: 950, county: '臺東縣', city: '臺東市' },
  { id: 951, county: '臺東縣', city: '綠島鄉' },
  { id: 952, county: '臺東縣', city: '蘭嶼鄉' },
  { id: 953, county: '臺東縣', city: '延平鄉' },
  { id: 954, county: '臺東縣', city: '卑南鄉' },
  { id: 955, county: '臺東縣', city: '鹿野鄉' },
  { id: 956, county: '臺東縣', city: '關山鎮' },
  { id: 957, county: '臺東縣', city: '海端鄉' },
  { id: 958, county: '臺東縣', city: '池上鄉' },
  { id: 959, county: '臺東縣', city: '東河鄉' },
  { id: 961, county: '臺東縣', city: '成功鎮' },
  { id: 962, county: '臺東縣', city: '長濱鄉' },
  { id: 963, county: '臺東縣', city: '太麻里鄉' },
  { id: 964, county: '臺東縣', city: '金峰鄉' },
  { id: 965, county: '臺東縣', city: '大武鄉' },
  { id: 966, county: '臺東縣', city: '達仁鄉' },
  { id: 970, county: '花蓮縣', city: '花蓮市' },
  { id: 971, county: '花蓮縣', city: '新城鄉' },
  { id: 972, county: '花蓮縣', city: '秀林鄉' },
  { id: 973, county: '花蓮縣', city: '吉安鄉' },
  { id: 974, county: '花蓮縣', city: '壽豐鄉' },
  { id: 975, county: '花蓮縣', city: '鳳林鎮' },
  { id: 976, county: '花蓮縣', city: '光復鄉' },
  { id: 977, county: '花蓮縣', city: '豐濱鄉' },
  { id: 978, county: '花蓮縣', city: '瑞穗鄉' },
  { id: 979, county: '花蓮縣', city: '萬榮鄉' },
  { id: 981, county: '花蓮縣', city: '玉里鎮' },
  { id: 982, county: '花蓮縣', city: '卓溪鄉' },
  { id: 983, county: '花蓮縣', city: '富里鄉' },
  { id: 890, county: '金門縣', city: '金沙鎮' },
  { id: 891, county: '金門縣', city: '金湖鎮' },
  { id: 892, county: '金門縣', city: '金寧鄉' },
  { id: 893, county: '金門縣', city: '金城鎮' },
  { id: 894, county: '金門縣', city: '烈嶼鄉' },
  { id: 896, county: '金門縣', city: '烏坵鄉' },
  { id: 209, county: '連江縣', city: '南竿鄉' },
  { id: 210, county: '連江縣', city: '北竿鄉' },
  { id: 211, county: '連江縣', city: '莒光鄉' },
  { id: 212, county: '連江縣', city: '東引鄉' },
];

export function GetCountyList() {
  const set = new Set();
  const t = AREA_LIST.map((item) => ({
    value: item.county,
    label: item.county,
  }));
  const result = t.filter((item) => (!set.has(item.value) ? set.add(item.value) : false));
  return result;
}

export function GetCityList(county) {
  return AREA_LIST.filter((item) => item.county === county).map((item) => ({
    value: item.id,
    label: item.city,
  }));
}

export function GetCityByZipCode(code) {
  return AREA_LIST.filter((item) => item.id === parseInt(code, 10))[0]?.city;
}
